import { useMemo, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Link,
    Typography,
} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import cn from "classnames";
import { formatDate, formatNumber } from "../../../utility";
import Tag from "../atoms/Tag";
import TdocIcon from "../../../assets/images/tdoc-icon.svg";
import Radio from "../molecules/Form/Radio";
import classes from "./SubscriptionAccordion.module.css";

const SubscriptionAccordion = ({
    cards,
    subs,
    subscriptionData,
    setSubscriptionData,
    buttonClickHandler,
    cancelSubscriptionHandler,
}) => {
    const [isDetailsOpen, setDetailsOpen] = useState({
        subscription: false,
        package: false,
    });

    const packages = useMemo(
        () => ({
            1: { name: subs[1].name, price: subs[1].price },
            2: { name: subs[2].name, price: subs[2].price },
            3: { name: subs[3].name, price: subs[3].price },
            4: { name: subs[4].name, price: subs[4].price },
        }),
        [subs]
    );

    const handleDetailsOpen = (detail) => {
        setDetailsOpen({
            subscription: false,
            package: false,
            [detail]: true,
        });
    };

    if (!subscriptionData?.subscription) {
        return null;
    }

    const filteredPackages = Object.entries(packages).filter(
        ([key]) => key !== "1"
    );

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={
                        <ArrowDropDownIcon
                            fontSize="large"
                            classes={{ root: classes.icon }}
                        />
                    }
                    aria-controls={`accordion-1`}
                    id={`accordion-1`}
                    classes={{
                        content: classes.content,
                        expandIcon: classes.expandIcon,
                    }}
                >
                    <img src={TdocIcon} alt="" className={classes.tdoc} />
                    <Typography className={classes.heading}>
                        {subscriptionData.subscription.name}
                    </Typography>
                    <Tag>Aktív</Tag>
                    <div className={classes.showDetails}>
                        <p>Részletek mutatása</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.gridContainer}>
                        <div
                            className={cn(classes.gridItem, classes.gridItem1)}
                        >
                            <dl>
                                <dt>Aktív előfizetés típusa:</dt>
                                <dd>{subscriptionData.subscription.name}</dd>
                                <dt>Előfizetés díja:</dt>
                                <dd>
                                    {subscriptionData.subscription.package_price
                                        ? `${formatNumber(
                                              subscriptionData.subscription
                                                  .package_price
                                          )} `
                                        : null}{" "}
                                    Ft
                                    {subscriptionData.subscription
                                        .package_price === 0 &&
                                        "(ingyenes hónap)"}
                                </dd>
                                <dt>Hónapforduló:</dt>
                                <dd>
                                    {subscriptionData.subscription
                                        .payment_next_date
                                        ? formatDate(
                                              subscriptionData.subscription
                                                  .payment_next_date
                                          )
                                        : "-"}
                                </dd>
                            </dl>

                            {cards.length > 0 && (
                                <>
                                    {isDetailsOpen.subscription && (
                                        <ul className={classes.packages}>
                                            {filteredPackages.map(
                                                ([key, value]) => (
                                                    <li
                                                        key={`package-option-${key}`}
                                                    >
                                                        <Radio
                                                            label={` ${
                                                                value.name
                                                            } ( ${
                                                                value.price
                                                            } ${
                                                                key > 1
                                                                    ? "/ hónap"
                                                                    : ""
                                                            } Ft )`}
                                                            value={key}
                                                            checked={
                                                                +key ==
                                                                +subscriptionData.nextSubscription
                                                            }
                                                            onChange={() =>
                                                                setSubscriptionData(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        nextSubscription:
                                                                            parseInt(
                                                                                key
                                                                            ),
                                                                    })
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}

                                    {!isDetailsOpen.subscription ? (
                                        <Link
                                            component="button"
                                            onClick={() =>
                                                handleDetailsOpen(
                                                    "subscription"
                                                )
                                            }
                                            classes={{ root: classes.link }}
                                        >
                                            Előfizetés módosítása
                                        </Link>
                                    ) : (
                                        <div className={classes.gridFooter}>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    buttonClickHandler(
                                                        subscriptionData.nextSubscription
                                                    )
                                                }
                                                className={classes.submit}
                                                disabled={
                                                    !subscriptionData.nextSubscription ||
                                                    subscriptionData.nextSubscription ==
                                                        subscriptionData
                                                            .subscription
                                                            .next_month_package
                                                }
                                            >
                                                Módosítás
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {subscriptionData.nextSubscription !== 1 && (
                                <Link
                                    component="button"
                                    onClick={cancelSubscriptionHandler}
                                    classes={{ root: classes.link }}
                                >
                                    Előfizetés törlése
                                </Link>
                            )}
                        </div>
                        <div
                            className={cn(classes.gridItem, classes.gridItem2)}
                        >
                            <dl>
                                <dt>Aktív csomag tartalma:</dt>
                                <dd>
                                    <ul>
                                        <li>
                                            {subscriptionData.subscription
                                                .hotline_price === 0
                                                ? "Korlátlan egészségügyi hotline"
                                                : `Egészségügyi hotline ${
                                                      subs[
                                                          subscriptionData
                                                              .subscription.id
                                                      ].hotline
                                                  } Ft`}
                                        </li>

                                        <li>
                                            {subscriptionData.subscription
                                                .duty_weekday_price === 0 ? (
                                                <span>
                                                    Korlátlan általános orvosi
                                                    konzultáció
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    Általános orvosi konzultáció
                                                    <span
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        H-P:{" "}
                                                        {
                                                            subs[
                                                                subscriptionData
                                                                    .subscription
                                                                    .id
                                                            ].dutyWeekday
                                                        }{" "}
                                                        Ft
                                                    </span>
                                                    <span
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        SZ-V:{" "}
                                                        {
                                                            subs[
                                                                subscriptionData
                                                                    .subscription
                                                                    .id
                                                            ].dutyWeekend
                                                        }{" "}
                                                        Ft
                                                    </span>
                                                </span>
                                            )}
                                        </li>

                                        {subscriptionData.subscription.id ===
                                        1 ? (
                                            <li>
                                                Chat szakorvossal{" "}
                                                {
                                                    subs[
                                                        subscriptionData
                                                            .subscription.id
                                                    ].chat
                                                }{" "}
                                                Ft
                                            </li>
                                        ) : (
                                            <li>
                                                {subscriptionData.subscription
                                                    .id === 2 &&
                                                    `Chat szakorvossal ${
                                                        subs[
                                                            subscriptionData
                                                                .subscription.id
                                                        ].chatP
                                                    }% kedvezménnyel`}

                                                {(subscriptionData.subscription
                                                    .id === 3 ||
                                                    subscriptionData
                                                        .subscription.id ===
                                                        4) &&
                                                    (subs[
                                                        subscriptionData
                                                            .subscription.id
                                                    ].chat === "korlátlan"
                                                        ? "Korlátlan chat a szakorvossal"
                                                        : `Chat szakorvossal ${
                                                              subs[
                                                                  subscriptionData
                                                                      .subscription
                                                                      .id
                                                              ].chat
                                                          } Ft`)}
                                            </li>
                                        )}

                                        {subscriptionData.subscription.id ===
                                        1 ? (
                                            <li>
                                                {`Másodvélemény ${
                                                    subs[
                                                        subscriptionData
                                                            .subscription.id
                                                    ].secondOpinion
                                                } Ft`}
                                            </li>
                                        ) : (
                                            <li>
                                                {`Másodvélemény ${
                                                    subs[
                                                        subscriptionData
                                                            .subscription.id
                                                    ].secondOpinionP
                                                }% kedvezménnyel ${
                                                    subs[
                                                        subscriptionData
                                                            .subscription.id
                                                    ].secondOpinion
                                                } Ft`}
                                            </li>
                                        )}

                                        {isDetailsOpen.package && (
                                            <>
                                                <li>
                                                    Videókonzultáció{" "}
                                                    {subscriptionData
                                                        .subscription
                                                        .video_consultation_discount_percentage >
                                                        0 &&
                                                        `${subscriptionData.subscription.video_consultation_discount_percentage}% kedvezménnyel`}{" "}
                                                    {subscriptionData
                                                        .subscription
                                                        .video_consultation_min_price
                                                        ? `${formatNumber(
                                                              subscriptionData
                                                                  .subscription
                                                                  .video_consultation_min_price
                                                          )} Ft `
                                                        : null}
                                                </li>
                                                <li>
                                                    Járóbetegellátás{" "}
                                                    <span>
                                                        {subscriptionData
                                                            .subscription
                                                            .outpatient_discount_percentage >
                                                            0 &&
                                                            `${subscriptionData.subscription.outpatient_discount_percentage}% kedvezménnyel`}
                                                    </span>
                                                </li>
                                                <li>
                                                    {subscriptionData
                                                        .subscription
                                                        .outpatient_discount_percentage >
                                                    0
                                                        ? `Akár ${subscriptionData.subscription.outpatient_discount_percentage}% kedvezmény a foglalás árából`
                                                        : "Időpontfoglalás listaáron"}
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </dd>
                            </dl>
                            <Link
                                component="button"
                                onClick={() => handleDetailsOpen("package")}
                                classes={{ root: classes.link }}
                            >
                                További szolgáltatások{" "}
                                {isDetailsOpen.package
                                    ? "elrejtése"
                                    : "megjelenítése"}
                            </Link>
                        </div>
                        <div
                            className={cn(classes.gridItem, classes.gridItem3)}
                        >
                            <dl>
                                <dt>Következő havi előfizetés:</dt>
                                <dd>
                                    {
                                        subscriptionData.subscription
                                            .nextMonthPackage
                                    }
                                </dd>

                                <dt>Következő fizetés összege:</dt>
                                <dd>
                                    {subscriptionData.subscription
                                        .next_month_package
                                        ? `${formatNumber(
                                              subs[
                                                  subscriptionData.subscription
                                                      .next_month_package
                                              ].price
                                          )} Ft`
                                        : null}
                                </dd>

                                <dt>Következő fizetés dátuma:</dt>
                                <dd>
                                    {subscriptionData.subscription.next_charge
                                        ? formatDate(
                                              subscriptionData.subscription
                                                  .next_charge
                                          )
                                        : "-"}
                                </dd>
                            </dl>
                            <Link
                                href="/profile"
                                classes={{ root: classes.link }}
                            >
                                Fizetési beállítások
                            </Link>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default SubscriptionAccordion;
